<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-gradient-dark opacity-6"></span>

    <div class="container my-auto">
      <div class="row">
        <div class="notifications">
          <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Sign in
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="login" role="form" class="text-start mt-3">
                <div class="mb-3">
                  <material-input id="email" type="tel" label="Email" v-model="form.email"
                    name="email" />
                </div>
                <div class="mb-3">
                  <material-input id="password" type="password" label="Password" v-model="form.password"
                    name="password" />
                </div>
                <div class="text-center">
                  <material-button class="my-4 mb-2" variant="gradient" @click="login()" color="success" fullWidth>Sign
                    in
                  </material-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar v-if="snackbar === 'danger'" title="" date=""
        description="Email or password is incorrect" :icon="{ component: 'campaign', color: 'white' }"
        color="danger" :close-handler="closeSnackbar" />
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
// import axios from 'axios'

export default {
  name: "admin-sign-in",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialAlert,
    MaterialSnackbar
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      snackbar: null,
      error: ''
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login: function () {
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/login", this.form).then(
        (data) => {
          if (data != undefined && !data.result.isSuccess) {
            this.snackbar = 'danger'
          } else {
            this.$router.push({ path: '/admin/dashboard' });
          }
        },
        (error) => {
          // this.successful = false;
          // this.loading = false;
          console.log('ERROR' + error)
        }
      );
    },
    closeSnackbar() {
      this.snackbar = null;
    },

  },
};
</script>

