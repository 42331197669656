<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-2" role="tablist">
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-2 active active" data-bs-toggle="tab"
          href="../../../examples/pages/account/settings.html" role="tab" aria-selected="true">Available</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" href="../../../examples/pages/account/billing.html"
          role="tab" aria-selected="false">Redeemed</a>
      </li>
      <li class="nav-item">
        <a class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" href="../../../examples/pages/account/invoice.html"
          role="tab" aria-selected="false">Expired</a>
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "nav-pill",
  mounted() {
    setNavPills();
  },
};
</script>
