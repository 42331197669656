<template>
  <nav aria-label="breadcrumb">
    <!-- <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item" :class="color">
        <a v-if="isRTL" :class="color" class="opacity-5 ps-2" href="#"
          >لوحات القيادة</a
        >
        <a v-else :class="color" class="opacity-8" href="#">Pages</a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="color"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol> -->
    <div class="row">
      <div class="col-lg-9 col-sm-9">
        <h6 class="mb-0 font-weight-bolder" :class="color">
          {{ currentPage }}
        </h6>
      </div>
      <div class="col-lg-3 col-sm-3">
        <material-button class="my-4 mb-2" variant="gradient" @click="createMerchant()" color="success" fullWidth
          data-bs-toggle="modal" data-bs-target="#addMerchantModal">
          Add Merchant
        </material-button>
      </div>
    </div>
  </nav>
  <!-- Modal -->
  <div class="modal fade" id="addMerchantModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-header pb-0 text-left">
              <h5 class="">Add New Merchant</h5>
            </div>
            <div class="card-body pb-3">
              <form @submit.prevent="createMer">
                <div class="row my-3">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">Code <i class="required">*</i></label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <div class="input-group input-group-outline null">
                      <input id="merchant_code" placeholder="Enter Code" type="text" class="form-control" v-model="form.merchant_code" />
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">Merchant Name <i class="required">*</i></label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <div class="input-group input-group-outline null">
                      <input id="brand_name" placeholder="Enter Merchant Name" type="text" class="form-control" v-model="form.brand_name" />
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">Logo</label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <div class="input-group">
                      <div class="custom-file" style="width: 100%;">
                        <div class="input-group input-group-outline null">
                          <input id="logo" placeholder="Enter Merchant Name" type="file" class="form-control" @change="handleFileUpload( $event )" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row my-3">
                  <div class="col-lg-3 col-sm-3">
                    <label class="form-label">Type</label>
                  </div>
                  <div class="col-lg-9 col-sm-9">
                    <div class="d-flex justify-content-evenly float-start">
                      <MaterialRadio v-model="form.type" id="brand" name="type" value="1">
                        Brand
                      </MaterialRadio>
                      <MaterialRadio v-model="form.type" id="parent" name="type" value="2">
                        Parent
                      </MaterialRadio>
                    </div>
                  </div>
                </div> -->
                <div class="row my-3">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">Parent Company ID</label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <select id="parent_merchant_id" class="form-select p-2" v-model="form.parent_merchant_id">
                      <option selected value="0">Open this select menu</option>
                      <option v-for="merchant in dataListAllMerchant" :key="merchant.id" :value="merchant.id">{{ merchant.brand_name }}</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer ">
          <div class="row w-60">
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn btn-cancel" data-bs-dismiss="modal" variant="gradient" color="danger"
                fullWidth>
                Cancel
              </material-button>
            </div>
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn" variant="gradient" @click="createMer()" color="success" fullWidth>
                Create
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
        :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
        :close-handler="closeSnackbar" />
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
// import MaterialInput from "@/components/MaterialInput.vue";
// import MaterialRadio from "@/components/MaterialRadio.vue";
import MerchantService from "../services/merchant.service";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import { mapState } from "vuex";

export default {
  name: "breadcrumbs",
  components: {
    MaterialButton,
    // MaterialInput,
    // MaterialRadio,
    MaterialSnackbar
  },
  data() {
    return {
      form: {
        merchant_code: '',
        brand_name: '',
        logo: '',
        type: 0,
        parent_merchant_id: 0,
        status: 0,
      },
      message: "",
      snackbar: null,
      loaderActive: false,
      dataListAllMerchant: []
    };
  },
  props: {
    currentPage: {
      type: String,
      required: "",
    },
    color: {
      type: String,
      default: "text-dark",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  created() {
    this.getParentCompany();
  },
  methods: {
    handleFileUpload() {
      this.form.logo = event.target.files[0];
    },
    createMer: function () {
      // let _this = this
      let formData = new FormData();
      formData.append('merchant_code', this.form.merchant_code);
      formData.append('brand_name', this.form.brand_name);
      formData.append('logo', this.form.logo);
      formData.append('type', this.form.type);
      formData.append('parent_merchant_id', this.form.parent_merchant_id);
      formData.append('status', this.form.status);
      // MerchantService.createMerchant(formData).then(
      //   (response) => {
      //     console.log(response)
      //     document.getElementsByClassName('btn-cancel').click()
      //     _this.snackbar = 'success'
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );
      MerchantService.createMerchant(formData).then(
        (response) => {
          console.log("response", response);
          if(response.data.result.isSuccess == false) {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
          } else {
              this.snackbar = "success";
              this.message = "Save successfully!";
              this.hideLoader();
              this.setTimeOutNotification();
              setTimeout(() => {
                window.location.href = "/admin/merchants/";
              }, 2000); 
              // document.getElementsByClassName('btn-cancel').click();
          }
        },
        (error) => {
            console.log(error);
            this.snackbar = "danger";
            this.message = error.response.data.result.message;
            this.hideLoader();
            this.setTimeOutNotification();
        }
      );
    },
    getParentCompany() {
      MerchantService.getAllMerchant().then(
          (response) => {
              this.dataListAllMerchant = response.data.data;
          },
          (error) => {
              console.log(error);
          }
      );
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    showLoader() {
        this.loaderActive = true;
    },
    hideLoader() {
        this.loaderActive = false;
    },
    setTimeOutNotification() {
        setTimeout(() => {
            this.snackbar = null;
        }, 3000);
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>