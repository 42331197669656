import api from './api';

class MerchantService {
  getListMerchant(dataForm) {
    return api.post('/admin/merchant', dataForm);
  }

  createMerchant(dataForm) {
    return api.post('/admin/merchant/add', dataForm, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteMerchant(merchantId) {
    return api.post('/admin/merchant/delete/' + merchantId);
  }

  getDetailMerchant(merchantId) {
    return api.post('/admin/merchant/detail/' + merchantId);
  }

  updateMerchant(dataForm) {
    return api.post("/admin/merchant/update/" + dataForm.id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getStripeRates(dataForm) {
    return api.post('/admin/merchant/list-stripe-rates', dataForm);
  }

  updateStripeRates(dataForm) {
    return api.post('/admin/merchant/update-stripe-rates', dataForm);
  }
  
  listBranchsByMechant(dataForm) {
    return api.post('/admin/merchant/list-branches-by-merchant', dataForm);
  }

  createBranch(dataForm) {
    return api.post('/admin/merchant/create-branches-by-merchant', dataForm);
  }

  deleteBranch(dataForm) {
    return api.post("/admin/merchant/delete-branch/" + dataForm.id, dataForm);
  }

  listCode() {
    return api.get('/admin/merchant/list-code');
  }

  assignCodeToBranch(dataForm) {
    return api.post('/admin/merchant/assign-code-to-branch', dataForm);
  }

  listCodeByBranch(dataForm) {
    return api.post('/admin/merchant/list-code-by-branch', dataForm);
  }

  deleteCodeAssignedToBranch(dataForm) {
    return api.post("/admin/merchant/delete-code-assigned-to-branch/" + dataForm.id, dataForm);
  }

  updateCode(dataForm) {
    return api.post("/admin/merchant/update-code/" + dataForm.id, dataForm);
  }

  createReader(dataForm) {
    return api.post('/admin/merchant/create-reader', dataForm);
  }

  listReaderByCode(dataForm) {
    return api.post('/admin/merchant/list-reader-by-code', dataForm);
  }

  getAllMerchant() {
    return api.get('/admin/merchant/parent');
  }

  createMerchantUserAccount(dataForm) {
    return api.post('/admin/merchant/update-or-create-user-account', dataForm);
  }

  listUserAccountByMechant(dataForm) {
    return api.post('/admin/merchant/list-all-user-account-by-merchant', dataForm);
  }

  updateStatusUserAccount(dataForm) {
    return api.post('/admin/merchant/update-status-user-account', dataForm);
  }

  resetPasswordUserAccount(dataForm) {
    return api.post('/admin/merchant/reset-password-user-account', dataForm);
  }

}

export default new MerchantService();
