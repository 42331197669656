<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-gradient-dark opacity-6"></span>
   
    <div class="container my-auto">
      <div class="row">
        <div class="notifications">
          <material-alert color="danger" icon="ni ni-like-2 ni-lg" dismissible>
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Sign in
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="login" role="form" class="text-start mt-3">
                <div class="mb-3">
                  <material-input id="email" type="tel" label="Phone number" v-model="form.phone_number" name="phone_number" />
                </div>
                <div class="mb-3">
                  <material-input id="password" type="password" label="Password" v-model="form.password" name="password" />
                </div>
                <div class="text-center">
                  <material-button class="my-4 mb-2" variant="gradient" @click="login()" color="success" fullWidth>Sign
                    in
                  </material-button>
                </div>
                <p class="mt-4 text-sm text-center">
                  Don't have an account?
                  <router-link :to="{ name: 'SignUp' }" class="text-success text-gradient font-weight-bold">Sign up
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations } from "vuex";
import axios from 'axios'

export default {
  name: "admin-logout",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialAlert,
  },
  data() {
    return {
      form: {
        phone_number: '',
        password: ''
      },
      snackbar: null,
      error: ''
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login: function () {
      var baseUrl = process.env.VUE_APP_DOMAIN_URL
      axios.post(baseUrl + 'login', this.form)
      .then((response) => {
        console.log(response)
        if(response.data.result.isSuccess) {
          localStorage.setItem('token', response.data.data.authorisation.token)
          this.$router.push({path: '/dashboard'});
        }
      })
      .catch(error => {
        this.error = error.response.data.result.message
      });
    },
    closeSnackbar() {
      this.snackbar = null;
    },

  },
};
</script>

