<template>
    <div class="container-fluid py-4">
      <div class="row mt-4">
        <div class="col-12">
          <div class="card">
            <div class="card-body px-0 pb-2">
              <div class="container">
                <div class="row">
                  <div class="col-12 mb-3">
                    <h3>MINTY TRANSACTION FEE</h3>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="row">
                      <div class="col-md-12">
                        <h5>DEFAULT</h5>
                      </div>
                    </div>
                    <div style="margin-top: 15px;" v-for="(item, index) in data.default" :key="index">
                      <div class="row">
                        <div class="col-md-12">
                          <div style="color: green">{{ item.title }}</div>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 15px;" v-for="(subItem, subIndex) in item.item" :key="subIndex">
                        <div class="col-md-2" style="align-items: center;display: inline-flex;justify-content: center;">
                          <div>{{ subItem.name }}</div>
                        </div>
                        <div class="col-md-2">
                          <select v-model="subItem.selectedOption" class="form-select p-2">
                            <option value="percentage">Percentage</option>
                            <option value="fixed">Fixed Amount</option>
                            <option value="combined">Combined</option>
                          </select>
                        </div>
                        <div class="col-md-2" v-if="subItem.selectedOption === 'percentage' || subItem.selectedOption === 'combined'">
                          <div class="input-group input-group-outline null">
                            <input v-model="subItem.percentageDefaulValue" type="text" class="form-control" placeholder="Enter percentage">
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-left: -25px;" v-if="subItem.selectedOption === 'percentage' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px;">% of payment amount</label>
                        </div>
                        <div class="col-md-2 d-flex" v-if="subItem.selectedOption === 'fixed' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px; margin-right: 5px;">S$</label>
                          <div class="input-group input-group-outline null">
                            <input v-model="subItem.fixedDefaulValue" type="text" class="form-control" placeholder="Enter amount">
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-left: -25px;" v-if="subItem.selectedOption === 'fixed' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px;">per transaction</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  <div class="col-12 mb-3">
                    <h5>EXCEPTIONS</h5>
                  </div>
                  <div class="col-12 mb-3" style="border: 1px solid lightgray; padding: 10px; border-radius: 10px;" v-for="(exception, index) in data.exceptions" :key="index">
                    <div style="position: absolute;right: 20px;">
                      <button class="btn btn-danger" @click="deleteException(index)">Delete</button>
                    </div>
                    <div style="margin-top: 15px;margin-bottom: 15px;" v-for="(item, itemIndex) in exception.items" :key="itemIndex">
                      <div class="row">
                        <div class="col-md-12">
                          <div style="color: green">{{ item.title }}</div>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 15px;" v-for="(subItem, subIndex) in item.item" :key="subIndex">
                        <div class="col-md-2" style="align-items: center;display: inline-flex;justify-content: center;">
                          <div>{{ subItem.name }}</div>
                        </div>
                        <div class="col-md-2">
                          <select v-model="subItem.selectedOption" class="form-select p-2">
                            <option value="percentage">Percentage</option>
                            <option value="fixed">Fixed Amount</option>
                            <option value="combined">Combined</option>
                          </select>
                        </div>
                        <div class="col-md-2" v-if="subItem.selectedOption === 'percentage' || subItem.selectedOption === 'combined'">
                          <div class="input-group input-group-outline null">
                            <input v-model="subItem.percentageDefaulValue" type="text" class="form-control" placeholder="Enter percentage">
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-left: -25px;" v-if="subItem.selectedOption === 'percentage' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px;">% of payment amount</label>
                        </div>
                        <div class="col-md-2 d-flex" v-if="subItem.selectedOption === 'fixed' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px; margin-right: 5px;">S$</label>
                          <div class="input-group input-group-outline null">
                            <input v-model="subItem.fixedDefaulValue" type="text" class="form-control" placeholder="Enter amount">
                          </div>
                        </div>
                        <div class="col-md-2" style="margin-left: -25px;" v-if="subItem.selectedOption === 'fixed' || subItem.selectedOption === 'combined'">
                          <label style="margin-top: 7px;">per transaction</label>
                        </div>
                      </div>
                    </div>
                    <Select2 :settings="{ multiple: 'true' }" v-model="exception.merchant" :options="merchants" class="auto-height"></Select2>
                  </div>
                  <div class="col-12 mb-3">
                    <button style="margin-right: 10px;" class="btn bg-gradient-success" @click="addException()">Add Exceptional Case <i class="bi bi-plus-circle"></i></button>
                    <button class="btn bg-gradient-success" @click="save()">Save <i class="bi bi-plus-circle"></i></button>
                  </div>
                </div>
              </div>
              <div class="position-fixed top-1 end-1 z-index-2 message-success">
                <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                  :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                  :close-handler="closeSnackbar" />
              </div>
              <material-loading :active="loaderActive" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Select2 from 'vue3-select2-component';
  import SettingFeesAndTaxes from '../../services/settingFeesAndTaxes.service'
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  
  export default {
    components: {
      Select2,
      MaterialSnackbar,
      MaterialLoading
    },
    mounted() {
        this.showLoader();
        this.loadList();
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        data: {
            default: [
                {
                    title_id: 1,
                    title: 'Online Payment',
                    item: [
                        { id: 1, name: 'VISA', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 2, name: 'MASTER CARD', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 3, name: 'AMEX', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 4, name: 'PAYNOW', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 5, name: 'Other', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 }
                    ]
                },
                {
                    title_id: 2,
                    title: 'Via Stripe Reader Payment',
                    item: [
                        { id: 1, name: 'VISA', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 2, name: 'MASTER CARD', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 3, name: 'AMEX', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 4, name: 'PAYNOW', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                        { id: 5, name: 'Other', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 }
                    ]
                }
            ],
            exceptions: [
                
            ],
        },
        merchants: [],
        scheduleDelete: []
      };
    },
    methods: {
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        setTimeOutNotification() {
            setTimeout(() => {
                this.snackbar = null;
            }, 3000);
        },
        loadList() {
            SettingFeesAndTaxes.getSettingFeesAndTaxes().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.merchants = response.data.data.merchant_dropdown;
                        if(response.data.data.items && response.data.data.items.default && response.data.data.items.default.length > 0 && response.data.data.items.default[0].item && response.data.data.items.default[0].item.length > 0) {
                            this.data = response.data.data.items;
                        }
                        this.isLoadData = true;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        addException() {
            if (!this.data.exceptions) {
                this.data.exceptions = [];
            }
            this.data.exceptions.push({
                    id: 0,
                    merchant: [],
                    items: [
                        {
                            title_id: 1,
                            title: 'Online Payment',
                            item: [
                                { id: 1, name: 'VISA', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 2, name: 'MASTER CARD', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 3, name: 'AMEX', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 4, name: 'PAYNOW', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 5, name: 'Other', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 }
                            ]
                        },
                        {
                            title_id: 2,
                            title: 'Via Stripe Reader Payment',
                            item: [
                                { id: 1, name: 'VISA', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 2, name: 'MASTER CARD', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 3, name: 'AMEX', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 4, name: 'PAYNOW', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 },
                                { id: 5, name: 'Other', selectedOption: 'combined', percentageDefaulValue: 0, fixedDefaulValue: 0 }
                            ]
                        }
                    ]
                });
        },
        deleteException(index) {
            this.scheduleDelete.push(this.data.exceptions[index].id);
            this.data.exceptions.splice(index, 1);
        },
        save() {
            if(this.checkDuplicateMerchants(this.data.exceptions)) {
                this.snackbar = "danger";
                this.message = 'Duplicate Merchant!';
                this.setTimeOutNotification();
            } else {
                var data = {   
                    data: this.data,
                    scheduleDelete: this.scheduleDelete
                }
                console.log("data", data);
                this.showLoader();
                SettingFeesAndTaxes.create(data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.loadList();
                            this.snackbar = "success";
                            this.message = "Save successfully!"
                            this.setTimeOutNotification();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            }
        },
        checkDuplicateMerchants(data) {
            if(data && data.length > 0) {
                const merchantSet = new Set();
                let hasDuplicateMerchant = false;

                for (const entry of data) {
                    const merchants = entry.merchant;
                    for (const merchant of merchants) {
                        const merchantKey = merchant.toString();
                        if (merchantSet.has(merchantKey)) {
                            hasDuplicateMerchant = true;
                            break;
                        } else {
                            merchantSet.add(merchantKey);
                        }
                    }
                    if (hasDuplicateMerchant) {
                        break;
                    }
                }

                if (hasDuplicateMerchant) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
  };
  </script>
  
  <style>
    /* .select2-selection__arrow {
        height: 42px !important;
    }
    .select2-selection__rendered {
        height: 42px !important;
        margin-top: 7px;
    }
    .select2-selection.select2-selection--single {
        height: 42px !important;
    } */
    .auto-height .select2-container .select2-selection--multiple {
        min-height: 44px; /* Set the minimum height of the selection box */
        max-height: none !important; /* Override the max-height property set by the component */
    }
  </style>
  