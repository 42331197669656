<template>
    <div class="pagination_block p-4 d-flex justify-content-end">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li
            class="page-item"
            :class="{ disabled: pagination.prev_page_url == null }"
          >
            <button type="button" class="page-link" @click="loadData(1)">
              &laquo;
            </button>
          </li>
          <li
            class="page-item"
            :class="{ disabled: pagination.prev_page_url == null }"
          >
            <button
              type="button"
              class="page-link"
              @click="loadData(current_page - 1)"
            >
              Prev
            </button>
          </li>
          <li class="page-item" v-if="current_page - 2 > 0">
            <button
              type="button"
              class="page-link"
              @click="loadData(current_page - 2)"
            >
              {{ current_page - 2 }}
            </button>
          </li>
          <li class="page-item" v-if="pagination.prev_page_url">
            <button
              type="button"
              class="page-link"
              @click="loadData(current_page - 1)"
            >
              {{ current_page - 1 }}
            </button>
          </li>
          <li
            class="page-item"
            :class="{ active: pagination.current_page == current_page }"
          >
            <button type="button" class="page-link">
              {{ current_page }}
            </button>
          </li>
          <li class="page-item" v-if="pagination.next_page_url">
            <button
              type="button"
              class="page-link"
              @click="loadData(current_page + 1)"
            >
              {{ current_page + 1 }}
            </button>
          </li>
          <li class="page-item" v-if="current_page + 2 <= last_page">
            <button
              type="button"
              class="page-link"
              @click="loadData(current_page + 2)"
            >
              {{ current_page + 2 }}
            </button>
          </li>
          <li
            class="page-item"
            :class="{ disabled: pagination.next_page_url == null }"
          >
            <button
              type="button"
              class="page-link"
              @click="loadData(pagination.current_page + 1)"
            >
              Next
            </button>
          </li>
          <li
            class="page-item"
            :class="{ disabled: pagination.next_page_url == null }"
          >
            <button
              type="button"
              class="page-link"
              @click="loadData(pagination.last_page)"
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  <script>
  export default {
    name: "pagination-view",
    props: ["pagination", "current_page", "last_page", "pageTitle"],
    methods: {
      loadData(url) {
        if (this.pageTitle) {
          this.$parent.getList(url, this.pageTitle);
        } else {
          this.$parent.getList(url);
        }
  
      },
    },
  };
  </script>
  <style scoped>
  .page-item .page-link, .page-item span {
      border-radius: 0% !important;
      margin: 0px;
      width: 45px;
      height: 35px;
  }
  .page-item.disabled .page-link {
      background-color: #ebeff3;
  }
  </style>