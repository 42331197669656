class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("admin"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("admin"));
    return user?.data.authorisation.token;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("admin"));
    user.authorisation.token = token;
    localStorage.setItem("admin", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("admin"));
  }

  setUser(user) {
    console.log(JSON.stringify(user));
    localStorage.setItem("admin", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("admin");
  }
}

export default new TokenService();
