import api from './api';

class Module {
    getModule() {
        return api.get('/admin/module/get');
    }
    getExceptionCaseByMerchant() {
        return api.get('/admin/module/get-exception-case-by-merchant');
    }
    getMerchantRoles() {
        return api.get('/admin/module/get-merchant-roles');
    }
    addMerchantRoles(dataForm) {
        return api.post('/admin/module/add-merchant-roles', dataForm);
    }
    assignGroupToScreenDefault(dataForm) {
        return api.post('/admin/module/assign-group-to-screen-default', dataForm);
    }
    addNewUserGroup(dataForm) {
        return api.post('/admin/module/add-new-user-group', dataForm);
    }
}

export default new Module();
