import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "../views/Dashboard.vue";
import MyRewards from "../views/MyRewards.vue";
import AdminDashboard from "../views/Admin/Dashboard.vue";
import AdminMerchants from "../views/Admin/Merchants.vue";
import AdminReportsAnalytics from "../views/Admin/ReportsAnalytics.vue";
import AdminMembers from "../views/Admin/Members.vue";
import AdminSetting from "../views/Admin/Settings.vue";
import AdminSettingFeesAndTaxes from "../views/Admin/AdminSettingFeesAndTaxes.vue";
import AdminLogout from "../views/Admin/Logout.vue";
import Transaction from "../views/Transaction.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import AdminSignIn from "../views/Admin/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Catalogue from "../views/Catalogue.vue";
import AdminMerchantDetail from "../views/Admin/MerchantDetail.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/my-rewards",
    name: "My Rewards",
    component: MyRewards,
  },
  {
    path: "/transactions",
    name: "My Transactions",
    component: Transaction,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/rewards-catalogue",
    name: "Catalogue",
    component: Catalogue,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/admin/sign-in",
    name: "Admin SignIn",
    component: AdminSignIn,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/admin/dashboard",
    name: "Admin Dashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/reports-analytics",
    name: "Admin Reports Analytics",
    component: AdminReportsAnalytics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/merchants",
    name: "MERCHANT LIST",
    component: AdminMerchants,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/members",
    name: "Admin Members",
    component: AdminMembers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/setting-fees-taxes",
    name: "Admin Setting Fees And Taxes",
    component: AdminSettingFeesAndTaxes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/setting",
    name: "Admin Setting",
    component: AdminSetting,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/logout",
    name: "Admin Logout",
    component: AdminLogout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/admin/merchant-detail/:id?",
    name: "Admin Merchant Detail",
    component: AdminMerchantDetail,
    meta: {
      requiresAuth: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach((to) => {
//   if(to.meta.requiresAuth && !localStorage.getItem('admin')) {
//     return { name: 'Admin SignIn' }
//   }
//   if(to.meta.requiresAuth == false && localStorage.getItem('admin')) {
//     return { name: 'Admin Dashboard' }
//   }
// })

export default router;
