<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-md-12">
        <transaction-card />
      </div>
    </div>
  </div>
</template>

<script>
import TransactionCard from "./components/TransactionCard.vue";

export default {
  name: "Transaction",
  components: {
    TransactionCard,
  },
};
</script>
