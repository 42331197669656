<template>
  <div class="container-fluid py-4">
    <!-- <div class="row justify-content-end">
      <div class="col-lg-3 col-sm-3">
        <material-button class="my-4 mb-2" variant="gradient" @click="login()" color="success" fullWidth >
          Add Merchant
        </material-button>
      </div>
    </div> -->

    <form @submit.prevent="search" role="form" class="text-start mt-3">
      <div class="row align-items-center">
        <div class="col-lg-9 col-sm-9">
          <material-input id="search" type="text" value="" label="Search here" v-model="key_word" />
        </div>
        <div class="col-lg-3 col-sm-3">
          <material-button class="btn btn-primary toast-btn" variant="gradient" color="success" fullWidth>
            Search
          </material-button>
        </div>
      </div>

    </form>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      ID
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Code
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Merchant Name
                    </th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Logo
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Parent Company ID
                    </th>
                    <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Status
                    </th>
                    <th class="text-uppercase text-center text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in listMerchant" :key="key">
                    <td class="align-middle text-center text-sm">
                      <p class="text-xs font-weight-bold mb-0">{{ value.id }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <p class="text-xs font-weight-bold mb-0">{{ value.merchant_code }}</p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">{{ value.brand_name }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <img :src="value.logo" class="avatar avatar-sm border-radius-lg" alt="user2" />
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">{{ value.parent_merchant_name }}</p>
                    </td>
                    <td class="align-middle text-left text-sm">
                      <!-- <span class="badge badge-sm bg-success">{{ value.status }}</span> -->
                      <span :class="[value.status == 1 ? 'bg-success' : 'bg-secondary', 'badge badge-sm']">{{
                      value.status ? 'Active' : 'Deactive' }}</span>
                    </td>
                    <td class="w-20 align-middle text-left text-sm">
                      <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="editMerchant(value.id)">
                        <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                      </a>
                      <a v-if="value.status" class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="deactiveMerchant(value)">
                        Deactive
                      </a>
                      <a v-else class="btn btn-link text-danger text-gradient px-3 mb-0" href="javascript:;" @click="activeMerchant(value)">
                        Active
                      </a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      v-if="pagination.last_page > 1"
      :pagination="pagination"
      :current_page="pagination.current_page"
      :last_page="pagination.last_page"
    />
  </div>
  <!-- Modal -->
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Are you sure you want to delete this
            merchant ?</h5>
          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <material-button class="btn bg-gradient-secondary" data-bs-dismiss="modal">
            No
          </material-button>
          <material-button class="btn bg-gradient-primary" @click="deleteMer()" data-bs-dismiss="modal">
            Yes
          </material-button>
        </div>
      </div>
    </div>

    <div class="position-fixed top-1 end-1 z-index-2">
      <material-snackbar v-if="snackbar === 'success'" title="Material Dashboard" date="11 mins ago"
        description="Hello, world! This is a notification message." :icon="{ component: 'done', color: 'white' }"
        color="success" :close-handler="closeSnackbar" />
    </div>
  </div>

</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MerchantService from "../../services/merchant.service";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "admin-merchants",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialSnackbar,
    Pagination
  },
  data() {
    return {
      listMerchant: [],
      merchantId: null,
      key_word: '',
      snackbar: null,
      pagination: {},
      page: 1,
    };
  },
  mounted() {
    this.listMerchant = this.getList(this.page);
  },
  methods: {
    getList(page) {
      var dataForm = {
        search_keyword: this.key_word,
        page: page,
      };
      MerchantService.getListMerchant(dataForm).then(
        (response) => {
          console.log(response)
          this.listMerchant = response.data.data.items;
          this.pagination = response.data.data;
          this.page = this.pagination.current_page;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    search: function () {
      // alert(this.key_word)
      var dataForm = {
        search_keyword: this.key_word,
        page: this.page,
      };
      MerchantService.getListMerchant(dataForm).then(
        (response) => {
          console.log(response)
          this.listMerchant = response.data.data.items;
          this.pagination = response.data.data;
          this.page = this.pagination.current_page;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    setMerchantId: function (id) {
      this.merchantId = id
    },
    deleteMer: function () {
      MerchantService.deleteMerchant(this.merchantId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.listMerchant = this.getList(this.page);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    deactiveMerchant: function (value) {
      var data =  {
          id: value.id,
          merchant_code: value.merchant_code,
          brand_name: value.merchant_code,
          status: 0
      };
      MerchantService.updateMerchant(data).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.listMerchant = this.getList(this.page);
              this.snackbar = "success";
              this.message = "Save successfully!";
              this.hideLoader();
              this.setTimeOutNotification();
            }
          },
          (error) => {
              console.log(error);
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    activeMerchant: function (value) {
      var data =  {
          id: value.id,
          merchant_code: value.merchant_code,
          brand_name: value.merchant_code,
          status: 1
      };
      MerchantService.updateMerchant(data).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.listMerchant = this.getList(this.page);
              this.snackbar = "success";
              this.message = "Save successfully!";
              this.hideLoader();
              this.setTimeOutNotification();
            }
          },
          (error) => {
              console.log(error);
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    editMerchant(id) {
      this.$router.push(
        "/admin/merchant-detail/" + id + "?page=" + this.page
      );
    },
  },

};
</script>
