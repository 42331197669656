<template>
  <div class="container-fluid py-4">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div style="flex-direction: row;display: flex;align-content: center;align-items: center;justify-content: space-between;padding-left: 15px;padding-right: 15px;">
            <div>
              <span class="text-secondary text-xs font-weight-bold">GENERAL RULE</span>
            </div>
            <div>
              <material-button class="my-4 mb-2" variant="gradient" color="success" @click="addUserGroupName()">
                ADD NEW USER GROUPS
              </material-button>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-for="(value, key) in dataModuleHeader[0]" :key="key">
                      {{ value }}
                      <i v-if="key > 0" @click="editUserGroupName(key, value)" class="fas fa-pencil-alt text-dark me-2" aria-hidden="true" style="margin-left: 5px;"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataModule" :key="index">
                    <td @click="toggleSubModule(item)" v-if="!item.rootId">
                      <span
                        v-if="item.subModule"
                        class="text-secondary text-xs font-weight-bold"
                      >
                        {{ item.name }}
                        <i
                          :class="['fas', 'fa-caret-' + (item.isShowing && activeRootMerchantId == 0 ? 'up' : 'down'), 'text-dark', 'me-2']"
                          style="margin-left: 15px;"
                        ></i>
                      </span>
                      <span v-else class="text-secondary text-xs font-weight-bold">
                        {{ item.name }}
                      </span>
                    </td>
                    <td @click="toggleSubModule(item)" v-if="item.rootId && item.rootId == activeRootId">
                      <span class="text-secondary text-xs font-weight-bold" style="margin-left: 20px;">
                        {{ item.name }}
                      </span>
                    </td>

                    <template v-for="(value, key) in item.group" :key="key">
                      <th class="align-middle text-sm" v-if="!item.rootId">
                        <material-checkbox
                          :disabled="loaderActive"
                          @change="assignGroupToScreenDefault(item.id, value.id, 0)"
                          v-model="value.id"
                          :checked="value.checked"
                          v-if="!item.rootId"
                          :id="'checkboxId' + (index + 1)"
                          class="checkbox-auto-save"
                        >
                        </material-checkbox>
                      </th>

                      <th class="align-middle text-sm" v-if="item.rootId && item.rootId == activeRootId">
                        <material-checkbox
                          :disabled="loaderActive"
                          @change="assignGroupToScreenDefault(item.id, value.id, 0)"
                          v-model="value.id"
                          :checked="value.checked"
                          v-if="item.rootId && item.rootId == activeRootId"
                          :id="'checkboxId' + (index + 1)"
                          class="checkbox-auto-save"
                        >
                        </material-checkbox>
                      </th>
                      
                    </template>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" style="margin-top: 20px;">
          <div style="flex-direction: row;display: flex;align-content: center;align-items: center;justify-content: space-between;padding-left: 15px;padding-right: 15px;">
            <div>
              <span class="text-secondary text-xs font-weight-bold">EXCEPTION RULE</span>
            </div>
            <div>
              <material-button class="my-4 mb-2" variant="gradient" color="success" @click="modalAddNewMerchant()">
                Add Exception Case
              </material-button>
            </div>
          </div>
          <div class="card-body px-0 pb-2" v-for="(itemMerchant, key) in dataExceptionCaseByMerchant" :key="key">
            <div class="table-responsive p-0">
              <div style="flex-direction: row;display: flex;align-content: center;align-items: center;justify-content: space-between;padding-left: 15px;padding-right: 15px;">
                <div>
                  <span class="text-secondary text-xs font-weight-bold" style="color: lightgreen !important;">{{ itemMerchant.merchant_name }}</span>
                </div>
              </div>
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-for="(value, key) in dataModuleHeader[0]" :key="key">
                      {{ value }}
                      <i v-if="key > 0" @click="editUserGroupName(key, value)" class="fas fa-pencil-alt text-dark me-2" aria-hidden="true" style="margin-left: 5px;"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in dataModuleOther" :key="index">
                    <td @click="toggleSubModuleOther(item, itemMerchant.merchant_id)" v-if="!item.rootId">
                      <span
                        v-if="item.subModule"
                        class="text-secondary text-xs font-weight-bold"
                      >
                        {{ item.name }}
                        <i
                          :class="['fas', 'fa-caret-' + (item.isShowing && itemMerchant.merchant_id == activeRootMerchantId ? 'up' : 'down'), 'text-dark', 'me-2']"
                          style="margin-left: 15px;"
                        ></i>
                      </span>
                      <span v-else class="text-secondary text-xs font-weight-bold">
                        {{ item.name }}
                      </span>
                    </td>
                    <td @click="toggleSubModuleOther(item, itemMerchant.merchant_id)" v-if="item.rootId && item.rootId == activeRootIdOther && itemMerchant.merchant_id == activeRootMerchantId">
                      <span class="text-secondary text-xs font-weight-bold" style="margin-left: 20px;">
                        {{ item.name }}
                      </span>
                    </td>

                    <template v-for="(value, key) in item.group" :key="key">
                      <th class="align-middle text-sm" v-if="!item.rootId">
                        <material-checkbox
                          :disabled="loaderActive"
                          @change="assignGroupToScreenDefault(item.id, value.id, itemMerchant.merchant_id)"
                          v-model="value.id"
                          :checked="value.checked && value.merchant_array_id.includes(itemMerchant.merchant_id)"
                          v-if="!item.rootId"
                          :id="'checkboxId' + (index + 1)"
                          class="checkbox-auto-save"
                        >
                        </material-checkbox>
                      </th>

                      <th class="align-middle text-sm" v-if="item.rootId && item.rootId == activeRootIdOther && itemMerchant.merchant_id == activeRootMerchantId">
                        <material-checkbox
                          :disabled="loaderActive"
                          @change="assignGroupToScreenDefault(item.id, value.id, itemMerchant.merchant_id)"
                          v-model="value.id"
                          :checked="value.checked && value.merchant_array_id.includes(itemMerchant.merchant_id)"
                          v-if="item.rootId && item.rootId == activeRootIdOther && itemMerchant.merchant_id == activeRootMerchantId"
                          :id="'checkboxId' + (index + 1)"
                          class="checkbox-auto-save"
                        >
                        </material-checkbox>
                      </th>
                      
                    </template>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
              :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
              :close-handler="closeSnackbar" />
          </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </div>
  <button
    style="display: none"
    type="button"
    class="btn-edit-user-group-modal"
    data-bs-toggle="modal"
    data-bs-target="#editUserGroups"
  ></button>
  <div class="modal fade" id="editUserGroups" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-header pb-0 text-left">
              <h5 v-if="userGroupId == 0">ADD NEW USER GROUPS</h5>
              <h5 v-else>UPDATE USER GROUPS</h5>
            </div>
            <div class="card-body pb-3">
              <div class="row">
                <div class="row">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">User Group Name</label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <material-input id="merchant_code" v-model="userGroupName" placeholder="PREMIUM USER" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer ">
          <div class="row w-60">
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn btn-cancel btn-close-modal" data-bs-dismiss="modal" variant="gradient" color="danger"
                fullWidth>
                Cancel
              </material-button>
            </div>
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn" variant="gradient" color="success" fullWidth @click="addNewUserGroup()">
                Save
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    style="display: none"
    type="button"
    class="btn-add-new-merchant-modal"
    data-bs-toggle="modal"
    data-bs-target="#addNewMerchant"
  ></button>
  <div class="modal fade" id="addNewMerchant" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="card card-plain">
            <div class="card-body pb-3">
              <div class="row">
                <div class="row" v-for="(item, key) in merchant_array" :key="key">
                  <div class="col-lg-4 col-sm-4">
                    <label class="form-label">{{ item.brand_name }}</label>
                  </div>
                  <div class="col-lg-8 col-sm-8">
                    <material-checkbox
                      @change="addMerchantToArray(item.id)"
                      class="checkbox-auto-save"
                    >
                    </material-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer ">
          <div class="row w-60">
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn btn-cancel btn-close-new-merchant-modal" data-bs-dismiss="modal" variant="gradient" color="danger"
                fullWidth>
                Cancel
              </material-button>
            </div>
            <div class="col-lg-6 col-sm-6 my-1">
              <material-button class="btn toast-btn" variant="gradient" color="success" fullWidth @click="addNewMerchant()">
                Save
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ModuleService from '../../services/module.service'
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import $ from "jquery";

export default {
  name: "admin-settings",
  components: {
    MaterialButton,
    MaterialCheckbox,
    MaterialInput,
    MaterialLoading,
    MaterialSnackbar
  },
  data() {
      return {
        dataModuleHeader: [],
        dataModule: [],
        dataModuleOther: [],
        activeRootId: 0,
        activeRootIdOther: 0,
        activeRootMerchantId: 0,
        message: "",
        snackbar: null,
        loaderActive: false,
        userGroupId: "",
        userGroupName: 0,
        dataExceptionCaseByMerchant: [],
        merchant_array: [],
        dataAddExceptionCase: []
      };
  },
  mounted() {
      this.getData();
      this.getMerchant();
      this.getExceptionCaseByMerchant();
  },
  methods: {
    getData() {
      this.showLoader();
      ModuleService.getModule().then(
          (response) => {
              if (response.data.result.isSuccess) {
                  this.dataModuleHeader = response.data.data[0];
                  this.dataModule = response.data.data[1];
                  this.dataModuleOther = response.data.data[2];
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
              this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    getMerchant() {
      this.showLoader();
      ModuleService.getMerchantRoles().then(
          (response) => {
              if (response.data.result.isSuccess) {
                  this.merchant_array = response.data.data;
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
              this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    getExceptionCaseByMerchant() {
      this.showLoader();
      ModuleService.getExceptionCaseByMerchant().then(
          (response) => {
              if (response.data.result.isSuccess) {
                  this.dataExceptionCaseByMerchant = response.data.data;
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
              this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    showLoader() {
        this.loaderActive = true;
    },
    hideLoader() {
        this.loaderActive = false;
    },
    setTimeOutNotification() {
        setTimeout(() => {
            this.snackbar = null;
        }, 3000);
    },
    toggleSubModule(item) {
      if (item.subModule) {
        if(this.activeRootId != 0) {
          this.activeRootId = 0;
        } else {
          this.activeRootId = item.id;
        }
        item.isShowing = !item.isShowing;
      }
    },
    toggleSubModuleOther(item, merchant_id) {
      console.log(item)
      if (item.subModule) {
        if(this.activeRootIdOther != 0) {
          this.activeRootIdOther = 0;
          this.activeRootMerchantId = 0;
        } else {
          this.activeRootIdOther = item.id;
          this.activeRootMerchantId = merchant_id;
        }
        item.isShowing = !item.isShowing;
      }
    },
    assignGroupToScreenDefault(module_id, group_user_id, merchant_id) {
      var data = {
        module_id: module_id,
        group_user_id: group_user_id
      }
      if(merchant_id > 0) {
        data.merchant_id = merchant_id;
      }
      // console.log(data);
      this.showLoader();
      ModuleService.assignGroupToScreenDefault(data).then(
          (response) => {
              if (response.data.result.isSuccess) {
                this.getData();
                this.snackbar = "success";
                this.message = "Save successfully!";
                this.setTimeOutNotification();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              // this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    addNewUserGroup() {
      var data = {
        id: this.userGroupId,
        user_group_name: this.userGroupName
      }
      this.showLoader();
      ModuleService.addNewUserGroup(data).then(
          (response) => {
              if (response.data.result.isSuccess) {
                this.getData();
                this.snackbar = "success";
                this.message = "Save successfully!";
                this.setTimeOutNotification();
                this.userGroupId = 0;
                this.userGroupName = "";
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    editUserGroupName(id, name) {
      this.userGroupId = id;
      this.userGroupName = name;
      $(".btn-edit-user-group-modal").click();
    },
    addUserGroupName() {
      this.userGroupId = 0;
      this.userGroupName = "";
      $(".btn-edit-user-group-modal").click();
    },
    modalAddNewMerchant() {
      this.userGroupId = 0;
      this.userGroupName = "";
      $(".btn-add-new-merchant-modal").click();
    },
    addNewMerchant() {
      // console.log(this.dataAddExceptionCase);
      var data = {
        data_add: this.dataAddExceptionCase
      }
      this.showLoader();
      ModuleService.addMerchantRoles(data).then(
          (response) => {
              if (response.data.result.isSuccess) {
                this.getData();
                this.getMerchant();
                this.getExceptionCaseByMerchant();
                this.snackbar = "success";
                this.message = "Save successfully!";
                this.setTimeOutNotification();
                $(".btn-close-new-merchant-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
          }
      );
    },
    addMerchantToArray(merchant_id) {
      if (this.dataAddExceptionCase.includes(merchant_id)) {
        this.dataAddExceptionCase.splice(this.dataAddExceptionCase.indexOf(merchant_id), 1);
      } else {
        this.dataAddExceptionCase.push(merchant_id);
      }
    }
  }
};
</script>
