import api from './api';

class SettingFeesAndTaxes {
    getSettingFeesAndTaxes() {
        return api.get('/admin/setting-fees-taxes/get-setting-fees-and-taxes');
    }

    create(dataForm) {
        return api.post('/admin/setting-fees-taxes/create-or-update', dataForm, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        });
    }
}

export default new SettingFeesAndTaxes();
