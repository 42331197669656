<template>
    <div class="container-fluid py-4">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12">
                <div class="nav-wrapper position-relative end-0">
                    <ul class="nav nav-pills nav-fill p-2" role="tablist">
                        <li class="nav-item">
                            <a @click="changeTab(1)" href="#" class="nav-link mb-0 px-0 py-2 active active" data-bs-toggle="tab" role="tab" aria-selected="true">Basic Information</a>
                        </li>
                        <li class="nav-item">
                            <a @click="changeTab(2)" href="#" class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" role="tab" aria-selected="false">Physical Branches</a>
                        </li>
                        <li class="nav-item">
                            <a @click="changeTab(3)" href="#" class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" role="tab" aria-selected="false">Stripe Rates</a>
                        </li>
                        <li class="nav-item">
                            <a @click="changeTab(4)" href="#" class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" role="tab" aria-selected="false">Stripe Readers</a>
                        </li>
                        <li class="nav-item">
                            <a @click="changeTab(5)" href="#" class="nav-link mb-0 px-0 py-2" data-bs-toggle="tab" role="tab" aria-selected="false">Login Account</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="type == 1">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Status</label>
                            </div>
                            <div class="col-lg-4 col-sm-4" style="display: inline-flex;">
                                <MaterialRadio
                                    v-model="form.status"
                                    value="1"
                                >
                                    Active
                                </MaterialRadio>
                                <MaterialRadio
                                    v-model="form.status"
                                    value="0"
                                >
                                    Deactive
                                </MaterialRadio>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Merchant Code</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="merchant_code" placeholder="Merchant Code" type="text" class="form-control" v-model="form.merchant_code" :disabled="true">
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Merchant Name</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="merchant_name" placeholder="Merchant Name" type="text" class="form-control" v-model="form.brand_name" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Parent Company</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <select id="parent_merchant_id" class="form-select p-2" v-model="form.parent_merchant_id">
                                    <option selected value="0">Open this select menu</option>
                                    <option v-for="merchant in dataListAllMerchant" :key="merchant.id" :value="merchant.id">{{ merchant.brand_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Stripe Connected Account</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="stripe_connected_account" placeholder="Stripe Connected Account" type="text" class="form-control" v-model="connected_account" :disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Stripe URL</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="stripe_url" placeholder="Stripe URL" type="text" class="form-control" v-model="form.pos_stripe_url" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Stripe Api Key</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="stripe_api_key" placeholder="Stripe Api Key" type="text" class="form-control" v-model="form.pos_stripe_api_key" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Stripe Session Timeout</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="stripe_session_timeout" placeholder="Stripe Session Timeout" type="text" class="form-control" v-model="form.pos_stripe_session_timeout" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Stripe Paynow Session Timeout</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="input-group input-group-outline null">
                                    <input id="pos_stripe_paynow_session_timeout" placeholder="Stripe Paynow Session Timeout" type="text" class="form-control" v-model="form.pos_stripe_paynow_session_timeout" />
                                </div>
                            </div>
                        </div>
                        <div class="row my-3" style="align-items: center;">
                            <div class="col-lg-2 col-sm-2" style="width: 135px">
                                <label class="form-label">Logo</label>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <input
                                    style="display: none"
                                    type="file"
                                    id="image1"
                                    @change="handleFileUpload($event)"
                                    accept="image/*"
                                />
                                <material-button
                                    @click="uploadFile()"
                                    class="btn-sm bg-gradient-success"
                                >
                                    Upload
                                </material-button>
                            </div>
                        </div>
                        <div id="preview" class="ps-2" style="position: relative;width: fit-content;">
                            <img v-if="urlImagePreview"
                                :src="urlImagePreview" />
                            <i class="fa fa-times icon-close-sidenav-main danger" v-if="urlImagePreview"
                                style="position: absolute; top: -8px; right: -6px; color: #f44335; font-size: 20px;"
                                aria-hidden="true" @click="clearImage()"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="type == 2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="modal fade" id="addBranch" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-body p-0">
                                    <div class="card card-plain">
                                        <div class="card-header pb-0 text-left">
                                        <h5 class="">Add New Physical Branch</h5>
                                        </div>
                                        <div class="card-body pb-3">
                                        <div>
                                            <div class="row my-3">
                                                <div class="col-lg-2 col-sm-2">
                                                    <label class="form-label">ID</label>
                                                </div>
                                                <div class="col-lg-10 col-sm-10">
                                                    {{ branch_code }}
                                                </div>
                                            </div>
                                            <div class="row my-3">
                                                <div class="col-lg-2 col-sm-2">
                                                    <label class="form-label">Name <span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-lg-10 col-sm-10">
                                                    <div class="input-group input-group-outline null">
                                                        <input id="physical_branch_name" placeholder="Enter physical branch name" type="text" class="form-control" v-model="physicalBranch.name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row my-3">
                                                <div class="col-lg-2 col-sm-2">
                                                    <label class="form-label">Address</label>
                                                </div>
                                                <div class="col-lg-10 col-sm-10">
                                                    <div class="input-group input-group-outline null">
                                                        <input id="physical_branch_address" placeholder="Enter physical branch address" type="text" class="form-control" v-model="physicalBranch.address" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row my-3">
                                                <div class="col-lg-2 col-sm-2">
                                                    <label class="form-label">Status</label>
                                                </div>
                                                <div class="col-lg-10 col-sm-10 d-flex">
                                                    <MaterialRadio
                                                        v-model="physicalBranch.status"
                                                        value="1"
                                                    >
                                                        Active
                                                    </MaterialRadio>
                                                    <MaterialRadio
                                                        v-model="physicalBranch.status"
                                                        value="0"
                                                    >
                                                        Deactive
                                                    </MaterialRadio>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="modal-footer ">
                                    <div class="row w-60">
                                        <div class="col-lg-6 col-sm-6 my-1">
                                        <material-button class="btn toast-btn btn-cancel btn-close-modal-create-branch" data-bs-dismiss="modal" variant="gradient" color="danger"
                                            fullWidth>
                                            Cancel
                                        </material-button>
                                        </div>
                                        <div class="col-lg-6 col-sm-6 my-1">
                                        <material-button class="btn toast-btn" variant="gradient" @click="createBranch()" color="success" fullWidth>
                                            Create
                                        </material-button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-start mt-3">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-sm-9">
                                
                                </div>
                                <div class="col-lg-3 col-sm-3">
                                <material-button data-bs-toggle="modal" data-bs-target="#addBranch" class="btn btn-primary toast-btn" variant="gradient" color="success" fullWidth  @click="clearDataAddBranch()">
                                    ADD PHYSICAL BRANCH
                                </material-button>
                                </div>
                            </div>
                        </div>
                        <div class="text-start mt-3">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-sm-9">
                                    <div class="input-group input-group-outline null">
                                        <input id="search" type="text" label="Search here" class="form-control" v-model="textSearchBranch" />
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-3">
                                <material-button  @click="getList()" class="btn btn-primary toast-btn" variant="gradient" color="success" fullWidth>
                                    SEARCH
                                </material-button>
                                </div>
                            </div>
                        </div>
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    ID
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Branch Name
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Status
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="dataListBranchsByMerchant.length">
                                <template
                                    v-for="(value, key) in dataListBranchsByMerchant" :key="key"
                                >
                                    <tr :style="showDivBoxBranch == value.id ? 'background: lightgray;' : ''">
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">{{ value.id_text }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">{{ value.name }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p v-if="value.status == 1" class="text-xs text-secondary mb-0">Active</p>
                                            <p v-else class="text-xs text-secondary mb-0">Deactive</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="showBoxBranch(value)">
                                                <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                                            </a>
                                            <a class="btn btn-link text-danger text-gradient px-3 mb-0" data-bs-toggle="modal"
                                                data-bs-target="#deleteBranchModal" href="javascript:;" @click="confirmDeleteBranch(value.id)">
                                                <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="showDivBoxBranch && showDivBoxBranch == value.id" style="width: 100%;border: 1px solid gainsboro;border-radius: 5px;padding: 20px;padding-top: 0px;" class="physical-branch-form">
                                        <td colspan="4">
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <div class="d-flex justify-content-center align-items-center my-3">
                                                        <div class="pe-3">
                                                            <label class="form-label mb-0">Name <span class="text-danger">*</span></label>
                                                        </div>
                                                        <div class="input-group input-group-outline" style="max-width: 500px;">
                                                            <input id="physical_branch_name" placeholder="Enter physical branch name" v-model="physicalBranch.name" type="text" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <div class="d-flex justify-content-center align-items-center my-3">
                                                        <div class="pe-3">
                                                            <label class="form-label mb-0">Address</label>
                                                        </div>
                                                        <div class="input-group input-group-outline" style="max-width: 500px;">
                                                            <input id="physical_branch_address" placeholder="Enter physical branch name" v-model="physicalBranch.address" type="text" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <div class="d-flex justify-content-center align-items-center my-3">
                                                        <div class="pe-3">
                                                            <label class="form-label mb-0">Stripe Connected Account</label>
                                                        </div>
                                                        <div class="input-group input-group-outline" style="max-width: 500px;">
                                                            <input id="physical_branch_stripe_connected_account" placeholder="Stripe Connected Account" type="text" class="form-control" v-model="physicalBranch.stripe_user_id" :disabled="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <div class="d-flex justify-content-center align-items-center my-3">
                                                        <div class="pe-3">
                                                            <label class="form-label mb-0">Status</label>
                                                        </div>
                                                        <div class="input-group input-group-outline d-flex" style="max-width: 500px;">
                                                            <MaterialRadio
                                                                v-model="physicalBranch.status"
                                                                value="1"
                                                            >
                                                                Active
                                                            </MaterialRadio>
                                                            <MaterialRadio
                                                                v-model="physicalBranch.status"
                                                                value="0"
                                                            >
                                                                Deactive
                                                            </MaterialRadio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <material-button class="btn btn-primary toast-btn" variant="gradient" color="success" @click="createBranch()">
                                                    Save
                                                </material-button>
                                            </div>
                                            <!-- <hr /> -->
                                            <!-- <div class="row">
                                                <div class="col-4">
                                                    <div class="row my-3" style="align-items: center;">
                                                        <div class="col-lg-4 col-sm-4" style="width: 70px">
                                                            <label class="form-label">Search</label>
                                                        </div>
                                                        <div class="col-lg-8 col-sm-8">
                                                            <div class="input-group input-group-outline null">
                                                                <input id="search_code" label="Search here" type="text" class="form-control" v-model="textSearchListCodeByBranch" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="row my-3" style="align-items: center;margin-right: 0px;">
                                                        <material-button @click="getListCodeByBranch()" class="btn btn-primary toast-btn" variant="gradient" color="success">
                                                            Search
                                                        </material-button>
                                                    </div>
                                                </div>
                                                <div class="col-4"></div>
                                                <div class="col-2">
                                                    <div class="row my-3" style="align-items: center;margin-right: 0px;">
                                                        <material-button data-bs-toggle="modal" data-bs-target="#assignCodeToBranch" class="btn btn-primary toast-btn" variant="gradient" color="success" fullWidth>
                                                            Assign New Code
                                                        </material-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Code Name
                                                        </th>
                                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Code
                                                        </th>
                                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataListCodeByBranch.length">
                                                    <tr  v-for="(value, key) in dataListCodeByBranch" :key="key">
                                                        <td class="align-middle text-left text-sm">
                                                            <p class="text-xs text-secondary mb-0">{{ value.name }}</p>
                                                        </td>
                                                        <td class="align-middle text-center text-sm">
                                                            <p class="text-xs text-secondary mb-0">{{ value.code }}</p>
                                                        </td>
                                                        <td class="align-middle text-center text-sm">
                                                            <a class="btn btn-link text-danger text-gradient px-3 mb-0" data-bs-toggle="modal"
                                                                data-bs-target="#deleteCodeAssignedToBranchModal" href="javascript:;" @click="confirmDeleteCodeAssigned(value.id)">
                                                                <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                    <td colspan="3" class="text-center">No item available</td>
                                                    </tr>
                                                </tbody>
                                            </table> -->

                                            <div class="modal fade" id="assignCodeToBranch" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                                                aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-body p-0">
                                                        <div class="card card-plain">
                                                            <div class="card-header pb-0 text-left">
                                                            <h5 class="">Assign Code To Branch</h5>
                                                            </div>
                                                            <div class="card-body pb-3">
                                                                <form>
                                                                    <div class="row my-3">
                                                                        <div class="col-lg-3 col-sm-3">
                                                                            <label class="form-label">Code</label>
                                                                        </div>
                                                                        <div class="col-lg-9 col-sm-9">
                                                                            <select id="code_id" class="form-select p-2" v-model="code_id">
                                                                                <template v-for="(value, key) in dataListCode" :key="key">
                                                                                    <option :value="value.id">{{ value.code }}</option>
                                                                                </template>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row my-3">
                                                                        <div class="col-lg-3 col-sm-3">
                                                                            <label class="form-label">Code Name</label>
                                                                        </div>
                                                                        <div class="col-lg-9 col-sm-9">
                                                                            {{ dataListCode.find(entry => entry.id === code_id) ? dataListCode.find(entry => entry.id === code_id).name : "" }}
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div class="modal-footer ">
                                                            <div class="row w-60">
                                                                <div class="col-lg-6 col-sm-6 my-1">
                                                                <material-button class="btn toast-btn btn-close-code-assign-to-branch btn-cancel" data-bs-dismiss="modal" variant="gradient" color="danger"
                                                                    fullWidth>
                                                                    Cancel
                                                                </material-button>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-6 my-1">
                                                                <material-button class="btn toast-btn" variant="gradient" color="success" fullWidth @click="assignCodeToBranch()">
                                                                    Save
                                                                </material-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                <td colspan="4" class="text-center">No item available</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination
                            v-if="pagination.last_page > 1"
                            :pagination="pagination"
                            :current_page="pagination.current_page"
                            :last_page="pagination.last_page"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="type == 3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" style="overflow-x: auto;">
                        <table class="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Visa(%)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Visa Const($)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Master(%)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Master Const($)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        AMEX(%)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        AMEX Const($)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Paynow(%)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Paynow Const($)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Other(%)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Other($)
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p class="text-xs font-weight-bold mb-0">Online Payment</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-1" placeholder="Enter" v-model="dataStripeRates[0].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[0].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-2" placeholder="Enter" v-model="dataStripeRates[0].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[0].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-3" placeholder="Enter" v-model="dataStripeRates[1].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[1].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-4" placeholder="Enter" v-model="dataStripeRates[1].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[1].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-5" placeholder="Enter" v-model="dataStripeRates[2].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[2].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-6" placeholder="Enter" v-model="dataStripeRates[2].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[2].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[3].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[3].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[3].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[3].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[4].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[4].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 1">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[4].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[4].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div v-if="row_number == 1">
                                            <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="updateStripeRates()">
                                                Update
                                            </a>
                                            <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="cancelStripeRates()">
                                                Cancel
                                            </a>
                                        </div>
                                        <a v-else class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="editInlineRow(1)">
                                            <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="text-xs font-weight-bold mb-0">Via Stripe Reader Payment</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-1" placeholder="Enter" v-model="dataStripeRates[5].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[5].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-2" placeholder="Enter" v-model="dataStripeRates[5].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[5].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-3" placeholder="Enter" v-model="dataStripeRates[6].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[6].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-4" placeholder="Enter" v-model="dataStripeRates[6].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[6].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-5" placeholder="Enter" v-model="dataStripeRates[7].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[7].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-6" placeholder="Enter" v-model="dataStripeRates[7].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[7].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[8].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[8].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[8].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[8].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[9].percentage" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[9].percentage }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div class="input-group input-group-outline null" v-if="row_number == 2">
                                            <input type="text" class="form-control" id="rate-7" placeholder="Enter" v-model="dataStripeRates[9].fixed_amount" />
                                        </div>
                                        <p v-else class="text-xs text-secondary mb-0">{{ dataStripeRates && dataStripeRates.length > 0 && dataStripeRates[9].fixed_amount }}</p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <div v-if="row_number == 2">
                                            <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="updateStripeRates()">
                                                Update
                                            </a>
                                            <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="cancelStripeRates()">
                                                Cancel
                                            </a>
                                        </div>
                                        <a v-else class="btn btn-link text-dark px-3 mb-0" href="javascript:;" @click="editInlineRow(2)">
                                            <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i>Edit
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="type == 4">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Branch
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Code Name
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Code
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Account
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Date Created
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="dataListCodeByBranch.length">
                                <template
                                    v-for="(value, key) in dataListCodeByBranch" :key="key"
                                >
                                    <tr>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ value.branch_name }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">{{ value.name }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">{{ value.code }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">acct_1234</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <p class="text-xs text-secondary mb-0">{{ value.created_at_format }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <material-button
                                                @click="showBoxStripeReaders(value)"
                                                class="btn-sm bg-gradient-success"
                                            >
                                                Edit
                                            </material-button>
                                        </td>
                                    </tr>
                                    <tr v-if="showDivBoxStripeReaders == value.id">
                                        <td colspan="6" style="padding: 0px;">
                                            <div style="width: 100%;border: 1px solid gainsboro;border-radius: 5px;padding: 20px;padding-top: 0px;">
                                                <!-- <div class="row my-3" style="align-items: center;">
                                                    <div class="col-lg-4 col-sm-4" style="width: 155px">
                                                        <label class="form-label">Connected Account</label>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-4">
                                                        <div class="input-group input-group-outline null">
                                                            <input id="connect_account" label="Connected Account" type="text" class="form-control"  v-model="connected_account" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-sm-4">
                                                        <material-button
                                                            @click="saveConnectedAccount(value)"
                                                            class="btn-sm bg-gradient-success"
                                                        >
                                                            Save
                                                        </material-button>
                                                    </div>
                                                </div> -->
                                                <div style="align-items:center;display: inline-flex;justify-content: space-between;width: 100%; margin-top: 20px;">
                                                    <div style="width: 130px">
                                                        <label class="form-label" style="font-weight: bold;text-decoration: underline;">Reader</label>
                                                    </div>
                                                    <material-button
                                                        data-bs-toggle="modal" data-bs-target="#addReader"
                                                        @click="resetCreateReader('add')"
                                                        class="btn-sm bg-gradient-success btn-create-reader"
                                                    >
                                                        Add Reader
                                                    </material-button>
                                                    <material-button
                                                        style="display: none;"
                                                        data-bs-toggle="modal" data-bs-target="#addReader"
                                                        class="btn-sm bg-gradient-success btn-update-reader"
                                                    >
                                                        Update Reader
                                                    </material-button>
                                                </div>
                                                <div class="modal fade" id="addReader" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-body p-0">
                                                            <div class="card card-plain">
                                                                <div class="card-header pb-0 text-left">
                                                                <h5 class="" v-if="reader_id != ''">Update Reader</h5>
                                                                <h5 class="" v-else>Add New Reader</h5>
                                                                </div>
                                                                <div class="card-body pb-3">
                                                                <div>
                                                                    <div class="row my-3">
                                                                        <div class="col-lg-3 col-sm-3">
                                                                            <label class="form-label">Reader Id</label>
                                                                        </div>
                                                                        <div class="col-lg-9 col-sm-9">
                                                                            <div class="input-group input-group-outline null">
                                                                                <input id="reader_number_id" placeholder="Enter Reader Id" type="text" class="form-control" v-model="reader_number_id" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row my-3">
                                                                        <div class="col-lg-3 col-sm-3">
                                                                            <label class="form-label">CODE</label>
                                                                        </div>
                                                                        <div class="col-lg-9 col-sm-9">
                                                                            <div class="input-group input-group-outline null">
                                                                                <input id="reader_code" placeholder="Enter Reader Code" type="text" class="form-control" v-model="reader_code" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row my-3">
                                                                        <div class="col-lg-3 col-sm-3">
                                                                            <label class="form-label">Statement <br /> Descriptor</label>
                                                                        </div>
                                                                        <div class="col-lg-9 col-sm-9">
                                                                            <div class="input-group input-group-outline null">
                                                                                <input id="reader_statement" placeholder="Enter Statement Descriptor" type="text" class="form-control" v-model="reader_statement" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div class="modal-footer ">
                                                            <div class="row w-60">
                                                                <div class="col-lg-6 col-sm-6 my-1">
                                                                <material-button class="btn toast-btn btn-cancel btn-close-modal-create-reader" data-bs-dismiss="modal" variant="gradient" color="danger"
                                                                    fullWidth>
                                                                    Cancel
                                                                </material-button>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-6 my-1">
                                                                <material-button class="btn toast-btn" variant="gradient" @click="createReader()" color="success" fullWidth>
                                                                    Save
                                                                </material-button>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table class="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                ID
                                                            </th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                Code
                                                            </th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                Reader Id
                                                            </th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                Statement Descriptor
                                                            </th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="dataListReaderByCode.length">
                                                        <tr v-for="(value, key) in dataListReaderByCode" :key="key">
                                                            <td class="align-middle text-center text-sm">
                                                                <p class="text-xs font-weight-bold mb-0">{{ key + 1 }}</p>
                                                            </td>
                                                            <td class="align-middle text-center text-sm">
                                                                <p class="text-xs text-secondary mb-0">{{ value.code }}</p>
                                                            </td>
                                                            <td class="align-middle text-center text-sm">
                                                                <p class="text-xs text-secondary mb-0">{{ value.number }}</p>
                                                            </td>
                                                            <td class="align-middle text-center text-sm">
                                                                <p class="text-xs text-secondary mb-0">{{ value.statement }}</p>
                                                            </td>
                                                            <td class="align-middle text-center text-sm">
                                                                <material-button
                                                                    @click="showPopupEditReader(value)"
                                                                    class="btn-sm bg-gradient-success"
                                                                >
                                                                    Edit
                                                                </material-button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                        <td colspan="4" class="text-center">No item available</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                <td colspan="6" class="text-center">No item available</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="type == 5">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div style="display: inline-flex;justify-content: space-between;width: 100%;margin-bottom: 20px;">
                            <div>
                                <p class="text-md font-weight-bold mb-0">
                                    Login Accounts
                                </p>
                            </div>
                            <div>
                                <material-button
                                    @click="resetCreateMerchantUserAccount()"
                                    data-bs-toggle="modal" data-bs-target="#addAccountMerchantModal"
                                    class="btn-sm bg-gradient-success"
                                    style="margin-right: 10px;"
                                >
                                    Add Account
                                </material-button>
                                <material-button
                                    @click="downloadLoginUsers()"
                                    class="btn-sm bg-gradient-success"
                                >
                                    Export List
                                </material-button>
                            </div>
                        </div>
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    First Name
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Last Name
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Email
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Created Date
                                </th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody v-if="dataListUserAccountByMerchant.length">
                                <tr v-for="(value, key) in dataListUserAccountByMerchant" :key="key">
                                    <td class="align-middle text-center text-sm">
                                        <p class="text-xs text-secondary mb-0">
                                            {{ value.first_name }}
                                        </p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <p class="text-xs text-secondary mb-0">
                                            {{ value.last_name }}
                                        </p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <p class="text-xs text-secondary mb-0">
                                            {{ value.email }}
                                        </p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <p class="text-xs text-secondary mb-0">
                                            <!-- August 21, 2019 @ 1:01pm -->
                                            {{ value.created_at_formated }}
                                        </p>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                        <material-button
                                            @click="resetPasswordUserAccount(value.id)"
                                            class="btn-sm bg-gradient-success"
                                            style="margin-right: 10px;"
                                        >
                                            Reset Password
                                        </material-button>
                                        <material-button
                                            @click="editMerchantUserAccount(value)"
                                            class="btn-sm bg-gradient-success"
                                            data-bs-toggle="modal" data-bs-target="#addAccountMerchantModal"
                                        >
                                            Edit
                                        </material-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                <td colspan="6" class="text-center">No item available</td>
                                </tr>
                            </tbody>
                        </table>

                        <pagination
                            v-if="pagination.last_page > 1"
                            :pagination="pagination"
                            :current_page="pagination.current_page"
                            :last_page="pagination.last_page"
                        />

                        <div class="modal fade" id="addAccountMerchantModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body p-0">
                                <div class="card card-plain">
                                    <div class="card-header pb-0 text-left">
                                    <h5 class="" v-if="formUser.id == 0">ADD ACCOUNT</h5>
                                    <h5 class="" v-if="formUser.id > 0">UPDATE ACCOUNT</h5>
                                    </div>
                                    <div class="card-body pb-3">
                                    <form @submit.prevent="createMer">
                                        <div class="row my-3">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Email Address <i class="required">*</i></label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                            <input id="email_address" placeholder="Enter Email" type="text" class="form-control" v-model="formUser.email" />
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row my-3">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">First Name <i class="required">*</i></label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                            <input id="first_name" placeholder="Enter First Name" type="text" class="form-control" v-model="formUser.first_name" />
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row my-3">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Last Name <i class="required">*</i></label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                            <input id="last_name" placeholder="Enter Last Name" type="text" class="form-control" v-model="formUser.last_name" />
                                            </div>
                                        </div>
                                        </div>
                                        <!-- <div class="row my-3">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Phone Number</label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                            <input id="last_name" placeholder="Enter Phone Number" type="text" class="form-control" v-model="formUser.phone_number" />
                                            </div>
                                        </div>
                                        </div> -->
                                        <!-- <div class="row my-3">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Verify</label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                            <input id="verify" placeholder="Enter Verify" type="password" class="form-control" v-model="formUser.password" />
                                            </div>
                                        </div>
                                        </div> -->
                                        <div class="row my-3" v-if="formUser.id > 0">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Password</label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                                <a @click="resetPasswordUserAccount(formUser.id)" href="#" style="text-decoration: underline;">Reset</a>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row my-3" v-if="formUser.id > 0">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Status</label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                                <a v-if="formUser.status == 1" @click="updateStatusUserAccount()" href="#" style="text-decoration: underline;">Deactive</a>
                                                <a v-if="formUser.status == 0" @click="updateStatusUserAccount()" href="#" style="text-decoration: underline;">Active</a>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row my-3" v-if="formUser.id > 0">
                                        <div class="col-lg-4 col-sm-4">
                                            <label class="form-label">Last Login</label>
                                        </div>
                                        <div class="col-lg-8 col-sm-8">
                                            <div class="input-group input-group-outline null">
                                                <p>{{ formUser.last_login_at_formated }}</p>
                                            </div>
                                        </div>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                                </div>
                                <div class="modal-footer ">
                                <div class="row w-60">
                                    <div class="col-lg-6 col-sm-6 my-1">
                                    <material-button class="btn toast-btn btn-close-modal-create-merchant-account btn-cancel" data-bs-dismiss="modal" variant="gradient" color="danger"
                                        fullWidth>
                                        Cancel
                                    </material-button>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 my-1">
                                    <material-button v-if="formUser.id == 0" class="btn toast-btn" variant="gradient" color="success" fullWidth @click="createMerchantUserAccount()">
                                        Create
                                    </material-button>
                                    <material-button v-if="formUser.id > 0" class="btn toast-btn" variant="gradient" color="success" fullWidth @click="createMerchantUserAccount()">
                                        Update
                                    </material-button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center" v-if="type == 1">
            <div class="col-lg-12 col-sm-12" style="display: flex;justify-content: center;">
                <material-button
                    @click="saveAll()"
                    class="btn mb-0 bg-gradient-success btn-md null my-4 mb-2"
                >
                    Save
                </material-button>
            </div>
        </div>

        <div class="modal fade" id="deleteBranchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Are you sure you want to delete this
                        branch ?</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-footer">
                    <material-button class="btn bg-gradient-secondary" data-bs-dismiss="modal">
                        No
                    </material-button>
                    <material-button class="btn bg-gradient-primary" @click="handleDeleteBranch()" data-bs-dismiss="modal">
                        Yes
                    </material-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteCodeAssignedToBranchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title font-weight-normal" id="exampleModalLabel">Are you sure you want to delete this
                        code ?</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-footer">
                    <material-button class="btn bg-gradient-secondary" data-bs-dismiss="modal">
                        No
                    </material-button>
                    <material-button class="btn bg-gradient-primary" @click="deleteCodeAssigned()" data-bs-dismiss="modal">
                        Yes
                    </material-button>
                    </div>
                </div>
            </div>
        </div>

        <form :action="`${actionDownload}/admin/download-login-account`" method="POST">
            <input type="hidden" name="merchant_id" :value="this.$route.params.id" />
            <input type="hidden" name="action_export" value="download" />
            <input id="downloadFormLoginUsers" type="submit" class="d-none" />
        </form>

        <div class="position-fixed top-1 end-1 message-success" style="z-index: 9999;">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
            :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
            :close-handler="closeSnackbar" />
        </div>

        <material-loading :active="loaderActive" />
    </div>
</template>
  
<script>
    import setNavPills from "@/assets/js/nav-pills.js";
    // import MaterialInput from "@/components/MaterialInput.vue";
    import MaterialButton from "@/components/MaterialButton.vue";
    import $ from "jquery";
    import MerchantService from "../../services/merchant.service";
    import Pagination from "@/components/Pagination.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialRadio from "@/components/MaterialRadio.vue";
    import MaterialLoading from "@/components/MaterialLoading.vue";
  
    export default {
        name: "nav-pill",
        components: {
            // MaterialInput,
            MaterialButton,
            Pagination,
            MaterialSnackbar,
            MaterialRadio,
            MaterialLoading
        },
        mounted() {
            setNavPills();
            this.getParentCompany();
            this.getDetail();
        },
        data() {
            return {
                type: 1,
                urlImagePreview: null,
                showDivBox: false,
                showDivBoxBranch: false,
                form: {
                    id: 0,
                    merchant_code: '',
                    brand_name: '',
                    logo: null,
                    parent_company: '',
                    pos_stripe_url: '',
                    pos_stripe_api_key: '',
                    pos_stripe_session_timeout: '',
                    pos_stripe_paynow_session_timeout: '',
                    status: null,
                    parent_merchant_id: 0
                },
                physicalBranch: {
                    id: 0,
                    name: '',
                    address: '',
                    status: "0",
                    stripe_user_id: '',
                },
                dataStripeRates: [],
                row_number: 0,
                dataListBranchsByMerchant: [],
                pagination: {},
                page: 1,
                textSearchBranch: '',
                branch_code: '',
                idToUpdateBranch: 0,
                idToDeleteBranch: 0,
                message: "",
                snackbar: null,
                loaderActive: false,
                dataListCode: [],
                code_id: 0,
                textSearchListCodeByBranch: '',
                dataListCodeByBranch: [],
                idToDeleteCodeAssignedToBranch: 0,
                showDivBoxStripeReaders: 0,
                connected_account: '',
                reader_id: '',
                reader_code: '',
                reader_number_id: '',
                reader_statement: '',
                dataListReaderByCode: [],
                dataListAllMerchant: [],
                formUser: {
                    id: 0,
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone_number: 0,
                    password: '',
                    last_login_at_formated: '',
                    status: null
                },
                action_export_login_user: 'list',
                actionDownload: process.env.VUE_APP_BASE_URL
            };
        },
        methods: {
            showLoader() {
                this.loaderActive = true;
            },
            hideLoader() {
                this.loaderActive = false;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },
            changeTab(type) {
                this.type = type;
                if(type == 2) { // branchs list
                    this.dataListBranchsByMerchant = [];
                    this.dataListUserAccountByMerchant = [];
                    this.pagination = {};
                    this.page = 1;
                    this.getList(this.page);
                    this.getListCode();
                }
                if(type == 3) { // stripe rates
                    this.getStripeRates();
                }
                if(type == 4) { // stripe readers
                    this.showDivBoxStripeReaders = 0;
                    this.getListCodeByBranch(true);
                }
                if(type == 5) { // login account
                    this.dataListBranchsByMerchant = [];
                    this.dataListUserAccountByMerchant = [];
                    this.pagination = {};
                    this.page = 1;
                    this.getList(this.page);
                }
            },
            handleFileUpload(event) {
                let file = event.target.files[0];
                if (file != null || file != undefined) {
                    this.urlImagePreview = null;
                    if (
                        !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
                            file.type
                        )
                    ) {
                        // this.fieldRequired.isImageRequire = true;
                        // this.isErrorUploadImage = true;
                        // this.messageImageError = `File must be an image.`;
                    } else {
                        // this.isErrorUploadImage = false;
                        // this.messageImageError = "";
                        this.form.logo = file;
                        console.log(this.form.logo)
                        this.urlImagePreview = URL.createObjectURL(this.form.logo);
                    }
                } else {
                    this.form.logo = null;
                    this.urlImagePreview = null;
                    // this.fieldRequired.isImageRequire = true;
                    // this.isErrorUploadImage = true;
                    // this.messageImageError = `File must be an image.`;
                }
            },
            uploadFile() {
                $("#image1").click();
            },
            clearImage() {
                this.form.logo = null;
                this.urlImagePreview = null;
                // this.isImageRequire = false;
                // this.isErrorUploadImage = false;
                // this.isImageTooSmall = false;
            },
            showBox() {
                this.showDivBox = !this.showDivBox;
            },
            showBoxBranch(value) {
                // this.showDivBoxBranch = !this.showDivBoxBranch;
                this.showDivBoxBranch = value.id;
                this.idToUpdateBranch = value.id;
                this.physicalBranch.name = value.name;
                this.physicalBranch.address = value.address;
                this.physicalBranch.status = value.status.toString();
                this.physicalBranch.stripe_user_id = value.stripe_user_id;
                this.created_at = value.created_at;
                // this.getListCodeByBranch();
            },
            getDetail() {
                var merchantId = this.$route.params.id;
                MerchantService.getDetailMerchant(merchantId).then(
                    (response) => {
                        console.log(response)
                        this.form.id = response.data.data.id;
                        this.form.merchant_code = response.data.data.merchant_code;
                        this.form.brand_name = response.data.data.brand_name;
                        this.form.parent_company = response.data.data.parent_company;
                        this.form.parent_merchant_id = response.data.data.parent_merchant_id;
                        this.urlImagePreview = response.data.data.logo;
                        this.connected_account = response.data.data.connected_account;
                        this.form.pos_stripe_url = response.data.data.pos_stripe_url;
                        this.form.pos_stripe_api_key = response.data.data.pos_stripe_api_key;
                        this.form.pos_stripe_session_timeout = response.data.data.pos_stripe_session_timeout;
                        this.form.pos_stripe_paynow_session_timeout = response.data.data.pos_stripe_paynow_session_timeout;
                        this.form.status = response.data.data.status ? response.data.data.status.toString() : '0';
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            saveAll() {
                if(this.type == 1) {
                    MerchantService.updateMerchant(this.form).then(
                        (response) => {
                            console.log("response", response);
                            this.getDetail();
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                        },
                        (error) => {
                            console.log(error);
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        }
                    );
                }
            },
            getStripeRates() {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId
                }
                MerchantService.getStripeRates(dataForm).then(
                    (response) => {
                        console.log(response)
                        this.dataStripeRates = response.data.data;
                        this.row_number = 0;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            editInlineRow(row_number) {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId
                }
                MerchantService.getStripeRates(dataForm).then(
                    (response) => {
                        console.log(response)
                        this.dataStripeRates = response.data.data;
                        this.row_number = row_number;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            cancelStripeRates() {
                this.getStripeRates();
            },
            updateStripeRates() {
                var merchantId = this.$route.params.id;
                console.log(this.dataStripeRates);
                var dataForm = {
                    merchantId: merchantId,
                    data: this.dataStripeRates
                }
                MerchantService.updateStripeRates(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        this.getStripeRates();
                        this.row_number = 0;
                        this.snackbar = "success";
                        this.message = "Save successfully!";
                        this.hideLoader();
                        this.setTimeOutNotification();
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            getList(page) {
                var merchantId = this.$route.params.id;
                if(this.type == 2) {
                    var dataForm = {
                        merchantId,
                        page,
                        search_keyword: this.textSearchBranch
                    }
                    MerchantService.listBranchsByMechant(dataForm).then(
                        (response) => {
                            console.log(response)
                            this.dataListBranchsByMerchant = response.data.data.items;
                            this.pagination = response.data.data;
                            this.page = this.pagination.current_page;
                            this.branch_code = response.data.data.max_id;
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (this.type == 5) {
                    var dataForm1 = {
                        merchantId: merchantId,
                        page,
                        action_export: this.action_export_login_user
                    }
                    MerchantService.listUserAccountByMechant(dataForm1).then(
                        (response) => {
                            console.log(response)
                            this.dataListUserAccountByMerchant = response.data.data.items;
                            this.pagination = response.data.data;
                            this.page = this.pagination.current_page;
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            },
            createBranch() {
                var merchantId = this.$route.params.id;
                if(this.physicalBranch.name == "") {
                    this.snackbar = "danger";
                    this.message = "Please enter physical branch name";
                    this.hideLoader();
                    this.setTimeOutNotification();

                    return;
                }

                var dataForm = {
                    merchantId: merchantId,
                    name: this.physicalBranch.name,
                    address: this.physicalBranch.address,
                    status: this.physicalBranch.status
                }

                if(this.idToUpdateBranch > 0) {
                    dataForm.id = this.idToUpdateBranch;
                }

                MerchantService.createBranch(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.getList(this.page);
                            $(".btn-close-modal-create-branch").click();
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        }  
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            confirmDeleteBranch(id) {
                this.idToDeleteBranch = id;
            },
            handleDeleteBranch() {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId: merchantId,
                    id: this.idToDeleteBranch
                }
                MerchantService.deleteBranch(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        this.getList(this.page);
                        this.idToDeleteBranch = 0;
                        this.snackbar = "success";
                        this.message = "Delete successfully!";
                        this.hideLoader();
                        this.setTimeOutNotification();
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            clearDataAddBranch() {
                this.showDivBoxBranch =false;
                this.idToUpdateBranch = 0;
                this.physicalBranch = {
                    id: 0,
                    name: '',
                    address: '',
                    status: "0",
                    stripe_user_id: ''
                }
                this.created_at = '';
            },
            getListCode() {
                MerchantService.listCode().then(
                    (response) => {
                        console.log(response)
                        this.dataListCode = response.data.data;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            assignCodeToBranch() {
                if(this.code_id == 0) {
                    this.snackbar = "danger";
                    this.message = "Please select code";
                    this.hideLoader();
                    this.setTimeOutNotification();
                } else {
                    var merchantId = this.$route.params.id;
                    var dataForm = {
                        merchantId: merchantId,
                        branch_id: this.idToUpdateBranch,
                        code_id: this.code_id
                    }
                    MerchantService.assignCodeToBranch(dataForm).then(
                        (response) => {
                            console.log("response", response);
                            this.getListCodeByBranch();
                            $(".btn-close-code-assign-to-branch").click();
                            this.code_id = 0;
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                        },
                        (error) => {
                            console.log(error);
                            this.snackbar = "danger";
                            this.message = "Code existing!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                        }
                    );
                }
            },
            getListCodeByBranch(isWithoutBranch=false) {
                var merchantId = this.$route.params.id;
                var dataForm = {};
                if(isWithoutBranch == false) {
                    dataForm.merchantId = merchantId;
                    dataForm.branch_id = this.idToUpdateBranch;
                    dataForm.search_keyword = this.textSearchListCodeByBranch;
                    // var dataForm = {
                    //     branch_id: this.idToUpdateBranch,
                    //     search_keyword: this.textSearchListCodeByBranch
                    // }
                } else {
                    // var dataForm = {
                    //     merchant_id: merchantId
                    // }
                    dataForm.merchantId = merchantId;
                }
                MerchantService.listCodeByBranch(dataForm).then(
                    (response) => {
                        console.log(response)
                        this.dataListCodeByBranch = response.data.data;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            confirmDeleteCodeAssigned(id) {
                this.idToDeleteCodeAssignedToBranch = id;
            },
            deleteCodeAssigned() {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId: merchantId,
                    id: this.idToDeleteCodeAssignedToBranch
                };

                MerchantService.deleteCodeAssignedToBranch(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        this.getListCodeByBranch();
                        this.idToDeleteCodeAssignedToBranch = 0;
                        this.snackbar = "success";
                        this.message = "Delete successfully!";
                        this.hideLoader();
                        this.setTimeOutNotification();
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            showBoxStripeReaders(value) {
                this.showDivBoxStripeReaders = value.id;
                this.minty_assign_code_to_branch_id = value.minty_assign_code_to_branch_id;
                // this.connected_account = value.connected_account;
                this.listReader();
            },
            saveConnectedAccount(value) {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId: merchantId,
                    id: value.minty_assign_code_to_branch_id,
                    connected_account: this.connected_account
                }
                MerchantService.updateCode(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        this.snackbar = "success";
                        this.message = "Save successfully!";
                        this.hideLoader();
                        this.setTimeOutNotification();
                        this.getListCodeByBranch(true);
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            createReader() {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId: merchantId,
                    id: this.reader_id ? this.reader_id : 0,
                    number: this.reader_number_id,
                    code: this.reader_code,
                    statement: this.reader_statement,
                    code_id: this.minty_assign_code_to_branch_id
                }
                MerchantService.createReader(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        if(response.data.result.isSuccess == false) {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        } else {
                            this.listReader();
                            $(".btn-close-modal-create-reader").click();
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                        }
                        this.resetCreateReader('add');
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            listReader() {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    merchantId: merchantId,
                    minty_assign_code_to_branch_id: this.minty_assign_code_to_branch_id
                }
                MerchantService.listReaderByCode(dataForm).then(
                    (response) => {
                        console.log(response)
                        this.dataListReaderByCode = response.data.data;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            showPopupEditReader(item) {
                this.reader_id = item.id;
                this.reader_number_id = item.number;
                this.reader_code = item.code;
                this.reader_statement = item.statement;

                
                $(".btn-update-reader").click();
            },
            resetCreateReader(type) {
                if(type == 'add') {
                    this.reader_id = '';
                    this.reader_number_id = '';
                    this.reader_code = '';
                    this.reader_statement = '';
                }
            },
            getParentCompany() {
                MerchantService.getAllMerchant().then(
                    (response) => {
                        this.dataListAllMerchant = response.data.data;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            createMerchantUserAccount() {
                this.formUser.merchantId = this.$route.params.id;
                this.showLoader();
                MerchantService.createMerchantUserAccount(this.formUser).then(
                    (response) => {
                        console.log("response", response);
                        if(response.data.result.isSuccess == false) {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        } else {
                            this.changeTab(5);
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                            $(".btn-close-modal-create-merchant-account").click();
                            this.resetCreateMerchantUserAccount();
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error.response.data.result.message;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            resetCreateMerchantUserAccount() {
                this.formUser.id = 0;
                this.formUser.email = "";
                this.formUser.first_name = "";
                this.formUser.last_name = "";
                this.formUser.phone_number = 0;
                this.formUser.password = "";
                this.formUser.status = null;
            },
            editMerchantUserAccount(item) {
                this.formUser.id = item.id;
                this.formUser.email = item.email;
                this.formUser.first_name = item.first_name;
                this.formUser.last_name = item.last_name;
                this.formUser.phone_number = item.phone_number;
                this.formUser.password = "";
                this.formUser.last_login_at_formated = item.last_login_at_formated;
                this.formUser.status = item.status;
            },
            updateStatusUserAccount() {
                MerchantService.updateStatusUserAccount(this.formUser).then(
                    (response) => {
                        console.log("response", response);
                        if(response.data.result.isSuccess == false) {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        } else {
                            this.changeTab(5);
                            this.snackbar = "success";
                            this.message = "Save successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                            $(".btn-close-modal-create-merchant-account").click();
                            this.resetCreateMerchantUserAccount();
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error.response.data.result.message;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            resetPasswordUserAccount(id) {
                var merchantId = this.$route.params.id;
                var dataForm = {
                    id: id,
                    merchantId: merchantId
                }
                this.showLoader();
                MerchantService.resetPasswordUserAccount(dataForm).then(
                    (response) => {
                        console.log("response", response);
                        if(response.data.result.isSuccess == false) {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.hideLoader();
                            this.setTimeOutNotification();
                        } else {
                            this.changeTab(5);
                            this.snackbar = "success";
                            this.message = "Sent successfully!";
                            this.hideLoader();
                            this.setTimeOutNotification();
                            $(".btn-close-modal-create-merchant-account").click();
                            this.resetCreateMerchantUserAccount();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        console.log(error);
                        this.snackbar = "danger";
                        this.message = error.response.data.result.message;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            downloadLoginUsers() {
                $('#downloadFormLoginUsers').click();
            }
        },
    };
</script>
<style scoped>
#preview img {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
}
.required {
  color: red;
}
.physical-branch-form .form-label {
    min-width: 175px;
}
</style>
  